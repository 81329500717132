.footer_container_wrapper {
    padding: 0 40px;
}

.footer_container_wrapper_sub {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    border: 8px solid rgba(255, 255, 255, 0.11);
    max-width: 1528px;
    margin: 0 auto 54px;
    border-radius: 29px;
}

.footer_container {
    max-width: 1528px;
    border-radius: 25px;
    background: url("../../assets/images/footer_bg.png");
    backdrop-filter: blur(11.100000381469727px);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding-top: 82px;
    padding-bottom: 61px;
    padding-left: 57px;
    padding-right: 103px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.footer_heading h1 {
    max-width: 560px;
    color: #000;
    font-size: 41px;
    font-weight: 700;
    letter-spacing: -0.41px;
}

.footer_address h2 {
    max-width: 234px;
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    padding-bottom: 14px;
    /* 144.444% */
}

.footer_address p {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    /* 144.444% */
}

.footer_address p a {
    text-decoration: none;
    color: inherit;
}

.footer_contact_content {
    display: block;
}

.footer_icons {
    display: flex;
    align-items: center;
    padding-top: 21px;
}

.contact_info div h2 a {
    color: inherit;
    text-decoration: none;
}

@media screen and (max-width: 1260px) {
    .footer_container {
        padding-left: 30px;
        padding-right: 30px;
    }

    .footer_heading h1 {
        max-width: 350px;
        font-size: 36px;
    }
}

@media screen and (max-width: 1060px) {
    .footer_heading h1 {
        max-width: 400px;
        font-size: 36px;
    }

    .footer_container {
        flex-wrap: wrap;
        row-gap: 40px;
    }

    .footer_contact_content {
        /* padding-top: 40px; */
    }
}

@media screen and (max-width: 768px) {
    .footer_container_wrapper {
        padding: 0 10px;
    }

    .footer_container {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 20px;
        padding-right: 20px;
        display: block;
        margin-bottom: 0px;
    }

    .footer_heading h1 {
        max-width: 350px;
        font-size: 26px;
        padding-bottom: 30px;
    }

    .footer_address h2 {
        max-width: none;
        font-size: 16px;
    }

    .footer_address p {
        font-size: 16px;
    }

    .footer_contact_content {
        padding-top: 21px;
    }
}