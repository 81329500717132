.team_container_wrapper {
    padding: 0 40px;
}

.team_container {
    max-width: 1240px;
    margin: 0 auto;
    padding-bottom: 110px;
}

.team_container h1 {
    color: #175DAF;
    text-align: center;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: -0.63px;
    padding-bottom: 54px;
}

/* .team_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 25px;
    column-gap: 10px;
}

.team_wrapper div {
    flex: 1 1 calc(25% - 20px); 
    max-width: calc(25% - 20px);
    box-sizing: border-box;
} */

.team_wrapper div img {
    width: 100%;
    border-radius: 10px;
}

.team_container img:hover {
    cursor: pointer;
    filter: brightness(0.95);
}

.team_wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between the two rows */
}

.row {
    display: flex;
    justify-content: space-between;
    gap: 10px; /* Space between the images */
}

.row1 div, .row2 div {
    flex: 1;
}

.row1 img, .row2 img {
    width: 100%; /* Image takes full width of its container */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensures images fill the container without distortion */
}

.row1 div {
    max-width: calc(100% / 5 - 10px);
   /* 4 images in the second row */
}
.row2 div {
    max-width: calc(100% / 5 - 10px); 
    justify-content: center;
     /* 5 images in the first row */
}

.row2 {
    justify-content: center !important;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
    .team_container_wrapper {
        padding: 0 40px;
    }

    .team_container h1 {
        font-size: 36px;
    }

    .team_wrapper div {
        flex: 1 1 calc(33.33% - 20px); /* Adjust for spacing */
        /* max-width: calc(33.33% - 20px); */
    }
}

@media (max-width: 768px) {
    .team_container_wrapper {
        padding: 0 20px;
    }

    .team_container h1 {
        font-size: 32px;
    }

    .team_wrapper div {
        flex: 1 1 calc(50% - 20px); /* Adjust for spacing */
        /* max-width: calc(50% - 20px); */
    }

    .row div {
        flex: 1 1 calc(33.33% - 10px); /* 3 items per row */
    }

}

@media (max-width: 560px) {
    .team_container_wrapper {
        padding: 0 10px;
    }

    .team_container h1 {
        font-size: 28px;
    }

    .team_wrapper div {
        flex: 1 1 100%; /* Full width */
        max-width: 100%;
    }

    .team_wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        gap: 10px; /* Adjust the spacing between images */
    }
    
    .team_wrapper div {
        display: flex;
        justify-content: center; /* Center images within their grid cells */
    }
    
    .team_wrapper img {
        max-width: 100%; /* Ensure images fit within their containers */
        height: auto; /* Maintain aspect ratio */
    }
    .partner_container {
        padding-bottom: 70px;
     }
     .footer_icons {
        display: ruby-text;
    }
    .footer_address {
        text-align: center;
    }
    .contact_info {
        padding: 9px 5px;
        margin-right: 10px;
    }
}



/* Default styles for larger screens (3 images per row) */
.team_wrapper .row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .team_wrapper .row div {
    flex: 1 0 33%; /* Adjust for 3 images per row */
    box-sizing: border-box;
  }
  
  /* Media query for screens with a max-width of 600px (2 images per row) */
  @media (max-width: 768px) {
    .team_wrapper .row div {
      flex: 1 0 50%; /* Adjust for 2 images per row */
    }
    h2 br {
        display: none;
    }
  }
  

  