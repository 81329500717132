.funds_container_wrapper {
    padding: 0 40px;
}

.funds_container {
    max-width: 1528px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url("../../assets/images/funds_bg.png");
    border-radius: 20px;
    box-shadow: 0px 3px 5.3px 0px rgba(0, 0, 0, 0.10);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    padding: 56px 52px;
    padding-bottom: 63px;
    margin-top: 74px;
}

.funds_icons {
    display: flex;
    align-items: center;
    margin-top: 46px;
}

.funds_icons div {
    margin-right: 18px;
}

.funds_content h3 {
    max-width: 701px;
    color: #2E2E2E;
    font-size: 26px;
    font-weight: 700;
    line-height: 33px;
    /* 126.923% */
    letter-spacing: -0.39px;
}

.funds_images {
    display: flex;
    justify-content: center;
    align-items: center;
}

.funds_images img {
    width: 100%;
}

.funds_icons div:hover {
    cursor: pointer;
    filter: brightness(0.95);
}

.funds_logo1 {
    max-width: 122px;
}

.funds_logo2 {
    max-width: 226px;
}

.funds_logo3 {
    max-width: 245px;
}

@media (max-width: 1240px) {
    .funds_container {
        padding: 20px;
        padding-bottom: 63px;
        padding-top: 50px;
        margin-top: 74px;
    }

    .funds_icons div {
        margin-right: 15px;
    }

    .funds_content h3 {
        max-width: 500px;
        font-size: 20px;
    }

    .funds_logo1 img {
        max-width: 100px;
        height: 77px;
    }

    .funds_logo2 img {
        max-width: 170px;
    }

    .funds_logo3 img {
        max-width: 180px;
    }
}

@media (max-width: 1040px) {
    .funds_container_wrapper {
        padding: 0 20px;
    }
    .funds_container {
        display: block;
        text-align: center;
    }

    .funds_images {
        max-width: 600px;
        margin: 0 auto;
        padding-top: 50px;
    }

    .funds_content h3 {
        max-width: none;
        margin: 0 auto;
        font-size: 20px;
    }
.funds_icons{
    display: flex;
    justify-content: center;
    align-items: center;
}
    .funds_images img {
        width: 100%;
    }

    .funds_icons div:hover {
        cursor: pointer;
        filter: brightness(0.95);
    }
}

@media (max-width: 680px) {
    .funds_container_wrapper {
        padding: 0 10px;
    }
    .funds_container {
        padding: 10px;
        padding-bottom: 30px;
        padding-top: 30px;
        margin-top: 40px;
    }

    .funds_icons div {
        margin-right: 10px;
    }

    .funds_content h3 {
        max-width: 400;
        margin: 0 auto;
        font-size: 18px;
    }

    .funds_icons {
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    .funds_logo1 img {
        max-width: 60px;
        height: 38px;
    }

    .funds_logo2 img {
        max-width: 80px;
    }

    .funds_logo3 img {
        max-width: 90px;
    }
}