.partner_container_wrapper {
    padding: 0 40px;
}

.partner_container {
    max-width: 1528px;
    margin: 0 auto;
    padding-top: 93px;
    padding-bottom: 101px;
    text-align: center;
    position: relative;
}

.arrow_img {
    position: absolute;
    top: 185px;
    left: 0;
}

.arrow_img img {
    width: 100%;
}

.partner_container h1 {
    color: #175DAF;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: -0.63px;
    padding-bottom: 61px;
}

.partner_image img {
    max-width: 328px;
    padding-bottom: 29px;
}

.partner_container p {
    max-width: 769px;
    color: #2E2E2E;
    font-size: 25px;
    font-weight: 500;
    line-height: 33px;
    margin: 0 auto;
    padding-bottom: 36px;
    /* 132% */
}

.partner_container h6 {
    color: #2E2E2E;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 10px;
}

.partner_container h5 {
    color: #2E2E2E;
    font-size: 29px;
    font-weight: 700;
    padding-bottom: 18px;
}

@media (max-width: 1096px) {
    .arrow_img{
        display: none;
    }
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
    .partner_container_wrapper {
        padding: 0 40px;
    }

    .partner_container h1 {
        font-size: 36px;
    }

    .partner_container p {
        font-size: 22px;
        line-height: 30px;
    }

    .partner_container h6 {
        font-size: 16px;
    }

    .partner_container h5 {
        font-size: 24px;
    }
}

@media (max-width: 768px) {
    .partner_container_wrapper {
        padding: 0 20px;
    }

    .arrow_img {
        top: 150px;
    }

    .partner_container h1 {
        font-size: 32px;
    }

    .partner_container p {
        font-size: 20px;
        line-height: 28px;
    }

    .partner_container h6 {
        font-size: 14px;
    }

    .partner_container h5 {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .partner_container_wrapper {
        padding: 0 10px;
    }

    .arrow_img {
        top: 120px;
    }

    .partner_container h1 {
        font-size: 28px;
    }

    .partner_container p {
        font-size: 18px;
        line-height: 26px;
    }

    .partner_container h6 {
        font-size: 12px;
    }

    .partner_container h5 {
        font-size: 18px;
    }
    .partner_image img {
        max-width: 230px;
        padding-bottom: 29px;
    }
}
