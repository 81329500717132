.charging_container_wrapper {
    padding: 0 40px;
}

.charging_container {
    max-width: 1528px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 72px;
    padding-bottom: 61px;
    flex-wrap: wrap;
}

.charging_container div {
    width: 100%;
}

.charging_image {
    /* max-width: 718px; */
    margin-right: 58px;
    flex: 1;
    margin-bottom: 20px;
}

.charging_image img {
    width: 100%;
    border-radius: 20px;
    background: #D9D9D9;
}

.charging_content {
    flex: 1;
}

.charging_content h6 {
    color: #2E2E2E;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.charging_content h1 {
    color: #183097;
    font-size: 42px;
    font-weight: 700;
    margin-top: 16px;
    margin-bottom: 17px;
    letter-spacing: -0.63px;
}

.charging_content p {
    color: #484848;
    font-size: 17px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: -0.255px;
    font-style: normal;
    /* 158.824% */
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
    .charging_container_wrapper {
        padding: 0 40px;
    }

    .charging_container {
        flex-direction: column;
        align-items: flex-start;
    }

    .charging_image {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .charging_content h1 {
        font-size: 36px;
    }

    .charging_content p {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .charging_container_wrapper {
        padding: 0 20px;
    }

    .charging_content h1 {
        font-size: 32px;
    }

    .charging_content p {
        font-size: 15px;
    }
}

@media (max-width: 480px) {
    .charging_container_wrapper {
        padding: 0 10px;
    }

    .charging_content h1 {
        font-size: 28px;
    }

    .charging_content p {
        font-size: 14px;
    }
}
