.handsfree_container_wrapper {
    padding: 0 40px;
}

.handsfree_container {
    max-width: 1528px;
    margin: 0 auto;
    border-radius: 28px;
    background: linear-gradient(99deg, #183097 7.6%, #175DAF 55.91%, #183097 97.06%);
    padding-top: 62px;
    padding-bottom: 86px;
}

.handsfree_wrapper div {
    width: 100%;
}

.handsfree_image {
    max-width: 590px;
}

.handsfree_image img {
    width: 100%;
}

.handsfree_content h1 {
    color: #FFF;
    text-align: center;
    font-size: 48px;
    font-weight: 700;
}

.handsfree_wrapper {
    max-width: 1250px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 59px;
    flex-wrap: wrap;
}

.handsfree_image h1 {
    color: #FFF;
    text-align: center;
    font-size: 26px;
    font-weight: 600;
    padding-top: 29px;
}

.handsfree_wrapper div {
    width: 100%;
    margin: 0 auto;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
    .handsfree_container_wrapper {
        padding: 0 40px;
    }

    .handsfree_container {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .handsfree_wrapper {
        flex-direction: column;
        align-items: center;
    }

    .handsfree_content h1 {
        font-size: 36px;
    }

    .handsfree_image h1 {
        font-size: 22px;
    }
}

@media (max-width: 768px) {
    .handsfree_container_wrapper {
        padding: 0 20px;
    }

    .handsfree_container {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .handsfree_content h1 {
        font-size: 32px;
    }

    .handsfree_wrapper {
        padding: 0 10px;
        padding-top: 50px;
    }

    .handsfree_image h1 {
        font-size: 20px;
        padding-top: 20px;
    }
}

@media (max-width: 480px) {
    .handsfree_container_wrapper {
        padding: 0 10px;
    }

    .handsfree_content h1 {
        font-size: 28px;
        padding: 0px 10px;
    }

    .handsfree_wrapper {
        padding-top: 40px;
    }

    .handsfree_image h1 {
        font-size: 16px;
        padding-top: 20px;
    }
}