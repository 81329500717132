.solution_container_wrapper {
    padding: 0 40px;
}

.solution_container {
    max-width: 1528px;
    margin: 0 auto;
    padding-top: 88px;
}

.solution_container h1 {
    color: #175DAF;
    text-align: center;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: -0.63px;
    margin-bottom: 23px;
}

.solution_container p {
    max-width: 654px;
    margin: 0 auto;
    color: #2E2E2E;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.3px;
}

.solution_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 69px;
    row-gap: 38px;
    column-gap: 52px;
}

.solution_content {
    flex: 1 1 calc(33.33% - 34.67px); /* Adjusting for three items per row with gaps */
    height: 100%;
    min-width: 300px; /* Ensuring the minimum width to avoid overlapping issues */
    min-height: 231px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../../assets/images/solution_bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    padding: 36px;
}

.solution_content:hover {
    filter: brightness(0.9);
    cursor: pointer;
}

.solution_content p {
    margin: 0 auto;
    color: #373737;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.36px;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
    .solution_container_wrapper {
        padding: 0 40px;
    }

    .solution_container h1 {
        font-size: 36px;
    }

    .solution_container p {
        font-size: 18px;
    }

    .solution_wrapper {
        row-gap: 30px;
        column-gap: 40px;
    }

    .solution_content {
        flex: 1 1 calc(50% - 30px); /* Two items per row */
    }
}

@media (max-width: 768px) {
    .solution_container_wrapper {
        padding: 0 20px;
    }

    .solution_container h1 {
        font-size: 32px;
    }

    .solution_container p {
        font-size: 16px;
    }

    .solution_wrapper {
        row-gap: 20px;
        column-gap: 20px;
    }

    .solution_content {
        flex: 1 1 calc(100% - 20px); /* One item per row */
        min-height: 200px;
    }

    .solution_content p {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .solution_container_wrapper {
        padding: 0 10px;
    }

    .solution_container h1 {
        font-size: 28px;
    }

    .solution_container p {
        font-size: 14px;
    }

    .solution_wrapper {
        row-gap: 10px;
        column-gap: 10px;
    }

    .solution_content {
        min-height: 180px;
    }

    .solution_content p {
        font-size: 18px;
    }
}
