.connect_container_wrapper {
    padding: 0 40px;
}

.connect_container {
    max-width: 1528px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    padding-bottom: 64px;
}

.connect_container > div {
    width: 100%;
}

.connect_image {
    width: 50%;
}

.connect_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
}

.connect_content {
    box-sizing: border-box;
    flex-grow: 1;
    margin-left: -50px;
    border-radius: 30px;
    background: #FFF;
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.11);
    padding-top: 52px;
    padding-bottom: 34px;
    padding-left: 64px;
    padding-right: 41px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.connect_content h1 {
    color: #175DAF;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -0.54px;
    padding-bottom: 10px;
}

.connect_content p {
    color: #2E2E2E;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.27px;
}

.connect_content h3 {
    color: #3E454B;
    font-size: 18px;
    font-weight: 600;
    padding-top: 37px;
    padding-bottom: 18px;
}

.contact_content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.contact_info {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.09);
    padding: 9px 14px;
    margin-right: 17px;
    margin-bottom: 10px;
}

.contact_info div img {
    width: 100%;
}

.contact_info div h2 {
    color: #525659;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    margin-left: 12px;
}

.contact_icon {
    margin-right: 10px;
    margin-bottom: 10px;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
    .connect_container_wrapper {
        padding: 0 40px;
    }

    .connect_container {
        flex-direction: column;
        align-items: flex-start;
    }

    .connect_image {
        width: 100%;
        margin-bottom: 20px;
    }

    .connect_content {
        margin-top: -50px;
        margin-left: 0;
        padding-left: 40px;
        padding-right: 40px;
    }

    .connect_content h1 {
        font-size: 32px;
    }

    .connect_content p {
        font-size: 16px;
    }

    .connect_content h3 {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .connect_container_wrapper {
        padding: 0 20px;
    }

    .connect_content {
        padding-left: 30px;
        padding-right: 30px;
    }

    .connect_content h1 {
        font-size: 28px;
    }

    .connect_content p {
        font-size: 15px;
    }

    .connect_content h3 {
        font-size: 14px;
    }
}

@media (max-width: 490px) {
    .connect_container_wrapper {
        padding: 0 10px;
    }

    .connect_content {
        padding-left: 20px;
        padding-right: 20px;
    }

    .connect_content h1 {
        font-size: 24px;
    }

    .connect_content p {
        font-size: 14px;
    }

    .connect_content h3 {
        font-size: 12px;
    }

}
