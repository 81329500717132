.m_navbar_main {
    display: none;
}

.navbar_wrapper {
    padding-left: 70px;
    padding-right: 70px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    width: 100%;
}

.navbar_container {
    max-width: 1528px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 43px;
    padding-bottom: 43px;

}

.logo_conatainer {
    cursor: pointer;
}

.menu_items {
    display: flex;
    justify-content: center;
    align-items: center;

}

.menu_text {
    width: 547px;
    margin-right: 16px;
}

.menu_text ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    border-radius: 52px;
    border: 2px solid var(--Grad_Joule, #183097);
    background: rgba(0, 0, 0, 0.17);
    padding: 7px 7px;
}

.menu_text ul li {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.14px;
    padding: 15px 30px;
}

.menu_text ul a {
    text-decoration: none;
}

.menu_text ul li:hover {
    border-radius: 30px;
    padding: 15px 30px;
    background: linear-gradient(99deg, #183097 7.6%, #175DAF 55.91%, #183097 97.06%);
    cursor: pointer;
    transition: 0.3 ease-in-out;
}

.menu_icon ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    border-radius: 52px;
    border: 2px solid #183097;
    background: rgba(0, 0, 0, 0.17);
    padding: 7px;
}

.menu_icon ul li {
    border-radius: 30px;
    background: #175DAF;
    padding: 15px 19px;
    cursor: pointer;
}

.youtube_icon {
    padding: 15px 16px;
    margin-left: 8px;
}


@media (max-width: 1260px) {

    .navbar_wrapper {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (max-width: 1060px) {
    .navbar_wrapper {
        padding-left: 30px;
        padding-right: 30px;
    }

    .navbar_container {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .logo_conatainer img {
        width: 190px;
    }

    .menu_text {
        width: 350px;
        margin-right: 8px;
    }

    .menu_text ul {
        padding: 5px 5px;
    }

    .menu_text ul li {
        font-size: 13px;
        padding: 10px 20px;
    }

    .menu_text ul li:hover {
        padding: 10px 20px;
    }

    .menu_icon ul {
        padding: 5.528px;
    }

    .menu_icon ul li {
        padding: 7px 10px;
    }

    .youtube_icon {
        padding: 8px 7px 4px 7px !important;
        margin-left: 5px;
    }
}


@media screen and (max-width: 768px) {
    .navbar_wrapper {
        position: static;
        padding-left: 0px;
        padding-right: 0px;
        background-color: black;
    }

    .m_navbar_logo img {
        width: 160px;
    }

    .m_navbar_main {
        display: block;
    }

    .navbar_container {
        display: none;
    }

    .m_navbar {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 11px 20px;
    }

    .m_navbar span {
        color: #fff;
        font-size: 30px;
        line-height: 35px;
    }

    .navbar_container .menu_container {
        display: none;
    }

    .icon_togle {
        padding-top: 9px;
    }

    .theme_btn_container {
        display: none;
    }

    .mobile_toggle_button {
        display: block;
    }


    .mobile_toggle_button {
        margin-left: 7px;
    }


    .mobile-menu {
        display: block;
        position: fixed;
        background-image: url("../../assets//images/footer_bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: #fff;
        height: 100vh;
        top: 0;
        right: 0;
        padding: 17px 20px;
        width: 65%;
        box-shadow: 0px 13px 35px -12px rgba(35, 35, 35, 0.15);
        transition: 0.5s;
        transform: translateX(0%);
        z-index: 92339;
    }

    .mobile-menu .navbar-nav {
        padding-top: 50px;
    }

    .mobile-menu.menu-open {
        transform: translateX(100%);
    }

    .nav-item {
        list-style: none;
        border-bottom: 1px solid black;
        padding: 10px 0;
        text-align: left;
    }

    .mobile-menu .nav-item .nav-link {
        color: black;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0;
        letter-spacing: 0.025rem;
        padding: 0;
        margin: 10px;
        /* padding-bottom: 10px; */
        transition: all 0.3s ease-out;
        position: relative;
        text-decoration: none;
    }

    .mobile-menu .nav-item .nav-link::before {
        position: absolute;
        content: "";
        left: 0;
        width: 0;
        height: 2px;
        background: #000;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        bottom: 0;
    }

    .mobile-menu .nav-item.active .nav-link::before {
        width: 100%;
    }


    .fix-icon {
        font-size: 23px;
        color: black !important;
        position: absolute;
        right: 17px;
        top: 25px;
        cursor: pointer;
        transform: scale(1.35);
    }

    .icon_li {
        list-style-type: none;
    }

    .o_icon {
        margin-right: 15px;
        transform: translate(0px, -1px);
    }

    .icons_container_nav_mob {
        cursor: pointer;
        text-align: center;
        margin-top: 10px;
    }

    .icon1 {
        color: #000;
        margin-right: 10px;
        font-size: 20px;
    }

    .icon2 {
        color: #000;
        font-size: 25px;
    }
}