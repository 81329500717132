.automation_container_wrapper {
    padding: 0 40px;
}

.automation_container {
    max-width: 1528px;
    margin: 0 auto;
}

.automation_container h1 {
    color: #175DAF;
    font-size: 36px;
    font-weight: 700;
    padding-bottom: 39px;
    letter-spacing: -0.54px;
    font-style: normal;
}

.automation_wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* flex-wrap: wrap; */
    column-gap: 41px;
    row-gap: 41px;
}


.automation_image {
    align-self: stretch;
    width: 100%;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.11);
    /* max-width: 351px; */
    /* max-height: 482px; */
    padding: 42px 19px;
    padding-right: 13px;
    text-align: center;
}

.automation_image img {
    width: 130px;
    padding-bottom: 35px;
}

.automation_image h2 {
    color: #3E3E3E;
    font-size: 21px;
    font-weight: 700;
    line-height: 30px;
    /* 142.857% */
    text-align: left;
    margin-bottom: 10px;
    letter-spacing: -0.315px;
    font-style: normal;
}

.automation_image p {
    color: #484848;
    font-size: 16px;
    line-height: 21px;
    text-align: left;
    letter-spacing: -0.24px;
    font-style: normal;
}

.automation_image:hover {
    filter: brightness(0.97);
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .automation_container_wrapper {
        padding: 0 10px;
    }

    .automation_container h1 {
        font-size: 26px;
        padding-bottom: 30px;
        text-align: center;
    }

    .automation_wrapper {
        row-gap: 20px;
    }


    .automation_image img {
        padding-bottom: 25px;
    }

    .automation_image h2 {
        font-size: 18px;
    }

}

@media (max-width: 1024px) {
    .automation_wrapper {
        flex-wrap: wrap;
    }

}