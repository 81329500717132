@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

.max_width_wrapper {
  padding: 0 70px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

/* @media screen and (max-width: 1160px) {
  body {
    padding: 0 30px;
  }
}

@media screen and (max-width: 768px) {
  body {
    padding: 0 10px;
  }
} */