.cover_container {
  position: relative;
}

.cover_image {
  width: 100%;
  display: flex;
  position: relative;
}

.cover_image_bg {
  background: rgba(0, 0, 0, 0.36);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cover_image img {
  width: 100%;
  height: auto;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.cover_image img.fade-out {
  opacity: 0;
}

.cover_image img.fade-in {
  opacity: 1;
}

.cover_content_wrapper {
  width: 100%;
  margin: 0 auto;
  position: absolute;
  bottom: 90px;
  left: 0px;
  padding: 0 70px;
}

.cover_content_wrapper_sub {
  width: 100%;
  max-width: 1528px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.cover_content {
  padding: 36px 19px;
  border-radius: 11px;
  background: rgba(0, 0, 0, 0.11);
  backdrop-filter: blur(17.850000381469727px);
  width: 551px;
}

.cover_content h1 {
  color: #FFF;
  font-size: 47px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: -0.47px;
  padding-left: 11px;
}

.cover_content p {
  color: #FFF;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.3px;
  padding-top: 17px;
  padding-bottom: 33px;
  padding-left: 11px;
}

.cover_content button {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  background-color: #175DAF;
  border-radius: 30px;
  padding: 21px 43px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.r_cover_btns button {
  width: 46px;
  height: 46px;
  border-radius: 74px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(17.850000381469727px);
  outline: none;
  color: white;
  border: none;
  cursor: pointer;
}

.r_cover_btns button:hover {
  filter: brightness(0.9);
}

.r_cover_btns button:nth-child(2) {
  margin-left: 9.2px;
}

video{
  height: 100%;
  width: 100%;
}


@media (max-width: 1224px) {


  .cover_content_wrapper {
    bottom: 45px;
    left: 0px;
    padding: 0 30px;
  }

  .cover_content {
    padding: 36px 9px;
    padding-right: 50px;
    border-radius: 11px;
    max-width: 551px;
    /* width: 100%; */
    width: auto;
  }

  .cover_content h1 {
    font-size: 42px;
  }

  .cover_content p {
    font-size: 24px;
  }

  .cover_content button {
    font-size: 14px;
    border-radius: 30px;
    padding: 17px 36px;
  }

  .r_cover_btns button {
    width: 46px;
    height: 46px;
    border-radius: 74px;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(17.850000381469727px);
    outline: none;
    color: white;
    border: none;
    cursor: pointer;
  }
}




@media (max-width: 1020px) {


  .cover_content_wrapper {
    bottom: 30px;
    left: 0px;
    padding: 0 30px;
  }

  .cover_content {
    padding: 20px 9px;
    padding-right: 50px;
    border-radius: 11px;
    max-width: 551px;
    /* width: 100%; */
    width: auto;
  }

  .cover_content h1 {
    font-size: 32px;
  }

  .cover_content p {
    font-size: 18px;
    /* padding: 0; */
    padding-top: 10px;
    padding-bottom: 10px;

  }

  .cover_content button {
    font-size: 12px;
    border-radius: 30px;
    padding: 12px 32px;
    margin-left: 11px;
  }

  .r_cover_btns button {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(17.850000381469727px);
    outline: none;
    color: white;
    border: none;
    cursor: pointer;
  }
}

@media (max-width: 876px) {

  .r_cover_btns {
    display: none;
  }


  .cover_content_wrapper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    padding: 0;
  }


  .cover_content_wrapper_sub {
    width: 100%;
    height: 100%;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cover_content {
    height: 100%;
    padding: 0;
    backdrop-filter: blur(1px);
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cover_content h1 br {
    display: none !important;
  }

  .cover_content h1 {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .cover_content p {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }


}



@media (max-width: 520px) {

  .cover_container {
    overflow: hidden;
  }

  video{
    width: auto !important;
  }  

  .cover_image {
    width: 150%;
    /* Adjust this value to increase the width */
    height: 400px;
    /* Set the desired height */
    display: flex;
    position: relative;
    overflow: hidden;
    /* Hide the extra content */
  }


  .cover_content {
    overflow: hidden;
    height: 100%;
    padding: 0 20px;
    backdrop-filter: blur(1px);
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .cover_content h1 br {
    display: none !important;
  }

  .cover_content h1 {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .cover_content p {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .cover_content button {
    margin: 0;
  }

}

@media (max-width: 480px) {
  .cover_content {
    padding: 0 10px;
  }
}