.news_container_wrapper {
    padding: 0 40px;
}

.news_container {
    max-width: 1528px;
    margin: 0 auto;
    padding-top: 63px;
    padding-bottom: 81px;
}

.news_wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 50px;
    /* flex-wrap: wrap; */
}

.news_container h1 {
    color: #175DAF;
    font-size: 36px;
    font-weight: 700;
    padding-bottom: 45px;
    text-align: center;
}

.news_image {
    width: 100%;
    align-self: stretch;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.11);
    padding: 16px;
    padding-bottom: 74px;
    margin-bottom: 20px;
}

.news_image > div, .news_image img {
    width: 100%;
}

.news_image:hover {
    filter: brightness(0.98);
    cursor: pointer;
}

.news_image img {
    border-radius: 19px;
}

.news_image h2 {
    color: #3E3E3E;
    font-size: 26px;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 15px;
}

.news_image p {
    max-width: 431px;
    color: #484848;
    font-size: 17px;
    font-weight: 400;
    line-height: 23px;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
    .news_container_wrapper {
        padding: 0 40px;
    }

    .news_wrapper {
        flex-direction: column;
        align-items: center;
    }

    .news_container h1 {
        font-size: 32px;
    }

    .news_image h2 {
        font-size: 22px;
    }

    .news_image p {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .news_container_wrapper {
        padding: 0 20px;
    }

    .news_container h1 {
        font-size: 28px;
    }

    .news_wrapper {
        column-gap: 30px;
    }

    .news_image h2 {
        font-size: 20px;
    }

    .news_image p {
        font-size: 15px;
    }
    .news_image {
        padding-bottom: 40px;
    }
}

@media (max-width: 480px) {
    .news_container_wrapper {
        padding: 0 10px;
    }

    .news_container h1 {
        font-size: 24px;
    }

    .news_image h2 {
        font-size: 18px;
    }

    .news_image p {
        font-size: 14px;
    }
}
